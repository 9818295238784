import type {Duration} from 'dayjs/plugin/duration';

export function convertCamelCaseToTitleCase(value: string) {
  return value.replace(/([a-z])([A-Z])/g, '$1 $2').replace(/(^\w|\s\w)/g, char => char.toUpperCase());
}

export function formatUnderscoreCase(value: string) {
  return value
    .toLowerCase()
    .replace(/_/g, ' ')
    .replace(/\b\w/g, char => char.toUpperCase())
    .trim();
}

export function addHttps(websiteDomain: string) {
  // some validator websites miss 'https://' at the beginning
  return /^https?:\/\//.test(websiteDomain) ? websiteDomain : `https://${websiteDomain}`;
}

export function formatDuration(duration: Duration) {
  const parts = [];

  const years = duration.years();
  if (years) {
    parts.push(`${years} year${years > 1 ? 's' : ''}`);
  }

  const months = duration.months();
  if (months) {
    parts.push(`${months} month${months > 1 ? 's' : ''}`);
  }

  const days = duration.days();
  if (days) {
    parts.push(`${days} day${days > 1 ? 's' : ''}`);
  }

  const hours = duration.hours();
  if (hours) {
    parts.push(`${hours} hour${hours > 1 ? 's' : ''}`);
  }

  const minutes = duration.minutes();
  if (minutes) {
    parts.push(`${minutes} minute${minutes > 1 ? 's' : ''}`);
  }

  const seconds = duration.seconds();
  if (seconds) {
    parts.push(`${seconds} second${seconds > 1 ? 's' : ''}`);
  }

  return parts.join(' ');
}
